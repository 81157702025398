<template>
    <div class="sf-banner-grid">
        <div class="sf-banner-grid__row">
            <div class="sf-banner-grid__col">
                <div class="sf-banner-grid__row">
                    <div class="sf-banner-grid__col">
                        <slot name="banner-A" />
                    </div>
                </div>
                <div class="sf-banner-grid__row">
                    <div class="sf-banner-grid__col">
                        <slot name="banner-B" />
                    </div>
                </div>
            </div>
            <div class="sf-banner-grid__col sf-banner-grid__col--medium">
                <slot name="banner-C" />
            </div>
            <div class="sf-banner-grid__col">
                <div class="sf-banner-grid__row">
                    <div class="sf-banner-grid__col">
                        <slot name="banner-D" />
                    </div>
                </div>
                <div class="sf-banner-grid__row">
                    <div class="sf-banner-grid__col">
                        <slot name="banner-E" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CustomBannerGrid"
};
</script>

<style lang="scss" scoped>
.sf-banner-grid {
  --banner-align-items: flex-start;
  --banner-container-width: 100%;
  --banner-description-margin: var(--spacer-xl) 0;
  &__row {
    display: var(--banner-grid-row-display, block);
    flex: var(--banner-grid-row-flex);
    margin: var(--banner-grid-row-margin, 0);
  }
  &__col {
    display: var(--banner-grid-col-display, block);
    flex: var(--banner-grid-col-flex);
    margin: var(--banner-grid-col-margin, 0);
    position: relative;
    overflow: hidden;
    & + & {
      --banner-grid-col-margin: var(--spacer-sm) 0 0 0;
    }
  }
  @include for-desktop {
    --banner-grid-row-display: flex;
    --banner-grid-row-flex: 0 0 100%;
    --banner-grid-col-display: flex;
    &__row {
      --banner-grid-row-margin: 0;
      & + & {
        --banner-grid-row-margin: var(--spacer-xl) 0 0 0;
      }
    }
    &__col {
      --banner-grid-col-flex: 1;
      flex-wrap: wrap;
      --banner-grid-col-margin: 0;
      & + & {
        --banner-grid-col-margin: 0 0 0 var(--spacer-xl);
      }
      & > .sf-banner,
      & > a {
        display: flex;
        flex: 1;
      }
      &--small { 
        --banner-grid-col-flex: 0 1 36%;
      }
      &--medium {
        --banner-grid-col-flex: 0 1 40%;
        --banner-container-width: 70%;
      }
      &--higher {
        --banner-height: 510px;
      }
    }
  }
}

</style>
